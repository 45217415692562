import { ReactElement, useState } from 'react';
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import AddFamilyMemberDetails from 'display-components/add-family-member/member-details';
import ContactInfoBox from 'display-components/contact-info-box';

// UI-ki
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Styles
import './add-family-member-send-invitation.styles.scss';
import { lowercaseAndCapitalize } from 'util/cart';

export type SendInvitationMethods = 'Email' | 'Sms';
interface MemberToAdd {
    firstName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    prescriptionNumber: string;
    insuranceID: string;
    accountType: number;
    email: string | null;
    phone: string | null;
}

interface AddFamilyMemberSendInvitationProps {
    dependentData: MemberToAdd;
    isBusy?: boolean;
    className?: string;
    onSubmit: (contactMethod: SendInvitationMethods) => void;
    onCancel: () => void;
}

const AddFamilyMemberSendInvitation = ({
    dependentData,
    isBusy,
    className,
    onSubmit,
    onCancel
}: AddFamilyMemberSendInvitationProps): ReactElement => {
    const { t } = useTranslation();
    const containerClasses = classNames('add-family-member-send-invitation', className);
    const [contactMethod, setContactMethod] = useState<SendInvitationMethods | null>(null);
    const [contactError, setContactError] = useState<boolean>(false);

    const handleRadio = (contactType: SendInvitationMethods) => {
        setContactError(false);
        setContactMethod(contactType);
    };

    const handleSubmit = () => {
        if (!contactMethod) {
            setContactError(true);
        } else {
            onSubmit(contactMethod);
        }
    };

    return (
        <div className={containerClasses}>
            <div className="add-family-member-send-invitation__main-container">
                <div className="add-family-member-send-invitation__message-container">
                    <ToastBox variant="info">
                        {t('components.addFamilyMember.sendInvitation.note', { name: dependentData.firstName })}
                    </ToastBox>
                </div>

                <div className="add-family-member-send-invitation__content-container">
                    <AddFamilyMemberDetails
                        name={lowercaseAndCapitalize(`${dependentData.firstName} ${dependentData.lastName}`)}
                        age={dependentData.age}
                        accountType={dependentData.accountType}
                        prescriptionNumber={dependentData.prescriptionNumber}
                        dob={dependentData.dateOfBirth}
                        insuranceID={dependentData.insuranceID}
                    />

                    <div className="add-family-member-send-invitation__contact-options-container">
                        <div className="options-text">
                            {t('components.addFamilyMember.sendInvitation.chooseInviteOption')}
                        </div>

                        <div className="card-list">
                            {/* will be handled with BE ticket */}
                            {contactError && (
                                <div className="card-list__error-message">
                                    {t('components.addFamilyMember.sendInvitation.contactSelectErrorMsg')}
                                </div>
                            )}
                            {dependentData.email && (
                                <ContactInfoBox
                                    id="add-dependent-send-invitation-as-email"
                                    contactData={dependentData.email}
                                    contactType="email"
                                    isChecked={!!(contactMethod === 'Email')}
                                    onChangeHandler={() => handleRadio('Email')}
                                    hasError={contactError}
                                />
                            )}
                            {dependentData.email && dependentData.phone && (
                                <div className="card-list__other-option">Or</div>
                            )}
                            {dependentData.phone && (
                                <ContactInfoBox
                                    id="add-dependent-send-invitation-as-phone"
                                    contactData={dependentData.phone}
                                    contactType="phone"
                                    isChecked={!!(contactMethod === 'Sms')}
                                    onChangeHandler={() => handleRadio('Sms')}
                                    hasError={contactError}
                                />
                            )}
                        </div>
                    </div>

                    <div className="add-family-member-send-invitation__actions-container">
                        <Button
                            async
                            className="btn-send-invite"
                            isBusy={isBusy}
                            disabled={isBusy || !contactMethod}
                            type="button"
                            label={t('components.addFamilyMember.sendInvitation.actions.submit')}
                            onClick={handleSubmit}
                        />
                        <Button
                            className="btn-cancel-invite"
                            type="button"
                            variant="text"
                            label={t('components.addFamilyMember.sendInvitation.actions.cancel')}
                            onClick={onCancel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFamilyMemberSendInvitation;
